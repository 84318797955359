import { BsArrowRightShort } from "react-icons/bs"
import { Button } from "../../core/Button/Button"
import { post } from "../../hooks/posts_hook";
import { TransactionDetails } from "../../types/api.types";
import { useSelector } from "react-redux";


export const Cybersource = () =>{
    const paymentDetails: TransactionDetails = useSelector((state: any) => state.payment);

const submitCybersource=()=>{
    let redirect_request = {
        onepay_transaction_id: paymentDetails.gateway_transaction_id
      };

      post(redirect_request, "https://onepay-cyber-checkout-live-dot-spemai-asia.el.r.appspot.com/api/v3/cybersource/redirect/");
}
    return(
        <div>
                 <div className="ps-3 pe-3 pt-3">
        <Button
          buttonText="Pay"
          type="button"
          onClickHandler={() => submitCybersource()}
          icon={<BsArrowRightShort />}
          className="pay-btn"
        />
      </div>



        </div>
    )
}