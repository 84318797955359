// import * as shajs from 'sha.js';
import { sha256, sha224 } from 'js-sha256';

export const jsonToShaValidator = (json: any, hash: string): boolean => {
  let jsonString = JSON.stringify(json);
  jsonString = jsonString.replace("'", "\"");
  jsonString = jsonString.replace(" ", "");
  jsonString = jsonString.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, "");

//   let objectSha = shajs('sha256').update(jsonString).digest('hex');
let objectSha = sha256.hex(jsonString);

  return objectSha === hash;
};

export const getJsonHash = (json: any): string => {
  try {
    let jsonString = JSON.stringify(json);
    jsonString = jsonString.replace("'", "\"");
    jsonString = jsonString.replace(" ", "");
    jsonString = jsonString.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, "");

    //return shajs('sha256').update(jsonString).digest('hex');
    return sha256.hex(jsonString);
  } catch (error) {
    return '';
  }
};
