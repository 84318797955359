import { TransactionDetails } from "../../types/api.types";
import { SET_PAYMENT_DETAILS } from "../paymentActions";

// Define the initial state with the TransactionDetails type
const initialState: TransactionDetails = {
    amount: '',
    merchant_logo: '',
    merchant_brand_name: '',
    merchant_transaction_id: '',
    gateway_transaction_id: '',
    security_key: '',
    app_id: '',
    is_live: false,
    redirect_url: '',
    assign_services: [],
    currency: '',
    is_sdk: 0,
    is_recurring: 0,
    app_name: '',
    is_shopify: false,
    shop_name: '',
    access_token: '',
    cancel_url: '',
    is_payment_link: false,
    brand_logo:'',
    banner_bg_color:'#051B13',
    banner_font_color:'#FFFFFF',
    action_bt_text:'',
    action_button_bt_color:'#7BC8A1',
    greeting_message:''
  };

  const paymentReducer = (state: TransactionDetails = initialState, action: any): TransactionDetails => {
    switch (action.type) {
      case SET_PAYMENT_DETAILS:
        return {
          ...state,
          ...action.payload, // Merge the existing state with the new values
        };
      default:
        return state;
    }
  };
  
  export default paymentReducer;
  