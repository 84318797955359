import { createApiInstance } from "./apiInstance";
import { apiCall } from "./apiCall";
import { Method } from "axios";

const sampathApi = createApiInstance(process.env.SERVER_SAMPATH_URL || "https://onepay-sampath-vishwa-uat-dot-spemai-asia.el.r.appspot.com/api/v3/");

export const sampathApiCall = (
  url: string,
  method: Method = 'GET',
  data: any = null,
  header: string | null = null,
  encrypt: boolean = false
): Promise<any> => {
  return apiCall(sampathApi, url, method, data, header, encrypt,false);
};
