import React, { useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { BsArrowRightShort } from 'react-icons/bs';
import { Button } from '../../../core/Button/Button';
import './CardElement.scss';
import master from '../../../assets/master.png';
import { TransactionDetails } from '../../../types/api.types';
import { sendCardDetailsAPI } from '../../../services/cardpayment.service';
import { logEvent } from '../../../analytics';
import { toast } from 'react-toastify';
import { encryptMessage } from '../../../utils/cardEncryption.util';

const getCardType = (num: any): string => {
  if (typeof num !== 'string') {
    return 'Invalid';
  }

  let number = num.replace(/\s/g, '');
  const cardPatterns = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/
  };

  if (cardPatterns.visa.test(number)) {
    return 'Visa';
  } else if (cardPatterns.mastercard.test(number)) {
    return 'MasterCard';
  } else if (cardPatterns.amex.test(number)) {
    return 'Amex';
  } else {
    return 'Invalid';
  }
};

const getMaxLength = (type: string) => {
  switch (type) {
    case 'Visa':
    case 'MasterCard':
      return 16;
    case 'Amex':
      return 15;
    default:
      return 16;
  }
};

const formatCardNumber = (number: string, type: string) => {
  if (type === 'Visa' || type === 'MasterCard') {
    return number.replace(/(\d{4})/g, '$1 ').trim();
  } else if (type === 'Amex') {
    return number.replace(/(\d{4})(\d{6})(\d{5})/, '$1 $2 $3').trim();
  } else {
    return number;
  }
};

const validationSchema = Yup.object().shape({
  nameOnCard: Yup.string()
    .required('Name on card is required')
    .max(32, 'Name on card must be at most 32 characters'),
  number: Yup.string()
    .required('Card number is required')
    .test('card-type', 'Invalid card number', value => getCardType(value || '') !== 'Invalid'),
  year: Yup.string().required('Expiry year is required'),
  securityCode: Yup.string()
    .required('Security code is required')
    .test('len', 'Invalid security code length', function (value: any) {
      const { number } = this.parent;
      const cardType = getCardType(number);
      if (cardType === 'Amex') {
        return value && value.length === 4;
      } else {
        return value && value.length === 3;
      }
    })
});

interface FormValues {
  nameOnCard: string;
  number: string;
  month: string;
  year: string;
  securityCode: string;
}

const ExpiryDateInput: React.FC<{
  field: any;
  form: any;
  inputRef: React.Ref<HTMLInputElement>;
  onExpiryComplete: () => void;
}> = ({ field, form, inputRef, onExpiryComplete }) => {
  const handleExpiryDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, '');
    console.log(value)
    if (value.length >= 4) {
      value = `${value.substring(0, 2)}/${value.substring(2, 4)}`;
      onExpiryComplete();
    }

    form.setFieldValue(field.name, value);
  };

  return (
    <input
      {...field}
      type="text"
      onChange={handleExpiryDateChange}
      maxLength={5}
      placeholder="MM/YY"
      className="w-100 txt-field"
      ref={inputRef}
      inputMode="numeric"  // This will trigger the numeric keypad
    />
  );
};

interface elementResponse {
  response: any;
}

const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
  const value = e.target.value.replace(/[0-9]/g, '');
  setFieldValue('nameOnCard', value);
};

const handleCVVInputChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
  const value = e.target.value.replace(/\D/g, '');
  setFieldValue('securityCode', value);
};

export const CardElement = ({ response }: elementResponse) => {
  const [cardType, setCardType] = useState<string>('');
  const expiryDateRef = useRef<HTMLInputElement>(null);
  const cvvRef = useRef<HTMLInputElement>(null);
  const paymentDetails: TransactionDetails = useSelector((state: any) => state.payment);
  console.log("card details", paymentDetails);
  const initialValues: FormValues = {
    nameOnCard: '',
    number: '',
    month: '',
    year: '',
    securityCode: '',
  };


  const getDeviceDetails = () => {
    const deviceDetails = {
        browser: navigator.userAgent,
        browserDetails: {
            javaEnabled: navigator.javaEnabled().toString(),
            javaScriptEnabled: "true",  // Since React relies on JavaScript
            language: navigator.language,
            screenHeight: window.screen.height.toString(),
            screenWidth: window.screen.width.toString(),
            timeZone: (new Date().getTimezoneOffset() * -1).toString(),
            colorDepth: window.screen.colorDepth.toString(),
            // Assuming full screen for the 3DSecureChallengeWindowSize
            "3DSecureChallengeWindowSize": "FULL_SCREEN"
        }
    };

    return deviceDetails;
};

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    // Handle form submission
    logEvent("User", "Clicked Button", "Payment Button");
    let expiredate = values.year.split("/");
    let requestData = {
      onepay_transaction_id: paymentDetails.gateway_transaction_id,
      "app_id": paymentDetails.app_id,
      "reference_id": paymentDetails.merchant_transaction_id,
      "gateway_type": getCardType(values.number) == "Amex" ? "NTB-AMEX" : "MASTERCARD",
      "card_type": getCardType(values.number) == "Amex" ? "NTB" : "MASTERCARD",
      'card_brand': getCardType(values.number),
      "card": encryptMessage({
        "month": expiredate[0],
        "year": expiredate[1],
        "nameOnCard": values.nameOnCard,
        "number": values.number.replace(/\s/g, ''),
        "securityCode": values.securityCode
      }),
      "device": {
        "browserDetails": getDeviceDetails()?getDeviceDetails():{}
      }
    }
    const card_response = await sendCardDetailsAPI(requestData)
    console.log("request data", requestData)
     console.log("request response",card_response)
     if(card_response.status===1000){
      await response(card_response)
     }else{
      console.log("vivisyed here")
      toast.error("Error Occured")
      logEvent("Error", "3DS error", card_response);
     }

    setSubmitting(false);
  };

  const handleCardNumberChange = async (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void) => {
    const rawValue = e.target.value.replace(/\D/g, '');
    const cardType = getCardType(rawValue);
    setCardType(cardType);
    const formattedValue = formatCardNumber(rawValue, cardType);
    await setFieldValue('number', formattedValue, true);
    setFieldTouched('number', true, true);

    if (rawValue.length === getMaxLength(cardType)) {
      expiryDateRef.current?.focus();
    }
  };

  const handleExpiryComplete = () => {
    cvvRef.current?.focus();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <div className="row ms-3 me-3">
              <label className="card-lbl ps-0">Name on Card</label>
              <Field
                type="text"
                name="nameOnCard"
                className="txt-field"
                placeholder="Enter card name"
                maxLength={32}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNameInputChange(e, formik.setFieldValue)}
              />
              <ErrorMessage className='error' name="nameOnCard" component="div" />
            </div>

            <div className="row ms-3 me-3">
              <label className="card-lbl ps-0">Card Number</label>
              <div className="d-flex justify-content-between txt-field">
                <Field
                  type="text"
                  name="number"
                  className="number-field w-75"
                  placeholder="**** **** **** ****"
                  inputMode="numeric"  // This will trigger the numeric keypad  
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCardNumberChange(e, formik.setFieldValue, formik.setFieldTouched)}
                />
                <div id="input--cc" className={`creditcard-icon--${cardType.toLowerCase()}`}></div>
              </div>
              <ErrorMessage className='error' name="number" component="div" />
            </div>

            <div className="row ms-3 me-3">
              <div className="col-6 ps-0">
                <div>
                  <label className="card-lbl ps-0">Expire Date</label>
                  <Field name="year">
                    {({ field, form }: { field: any, form: any }) => (
                      <ExpiryDateInput field={field} form={form} inputRef={expiryDateRef} onExpiryComplete={handleExpiryComplete} />
                    )}
                  </Field>
                  <ErrorMessage className='error' name="year" component="div" />
                </div>
              </div>

              <div className="col-6 pe-0">
                <div className="w-100">
                  <label className="card-lbl ps-0">CVV</label>
                  <Field
                    type="password"
                    name="securityCode"
                    className="w-100 txt-field"
                    placeholder="Enter CVV"
                    inputMode="numeric"
                    innerRef={cvvRef}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCVVInputChange(e, formik.setFieldValue)}
                  />
                  <ErrorMessage className='error' name="securityCode" component="div" />
                </div>
              </div>

              <div className="ps-0 pe-0">
                <Button
                  buttonText={paymentDetails.action_bt_text}
                  type='submit'
                  icon={<BsArrowRightShort />}
                  className="pay-btn"
                  style={{backgroundColor:paymentDetails.action_button_bt_color}}
                  isLoading={formik.isSubmitting}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
